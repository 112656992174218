import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CSVReader from 'react-csv-reader';

const EmptyTheClip = () => {
  const [bullets, setBullets] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [messageTemplate, setMessageTemplate] = useState('');
  const [queue, setQueue] = useState([]);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    fetchBullets();
  }, []);

  const fetchBullets = async () => {
    console.log('Fetching bullets...');
    try {
      const response = await axios.get('/api/get-phone-numbers');
      console.log('Response from API:', response.data);
      const phoneNumbers = response.data;
      const bulletsInChamber = {};

      for (const key in phoneNumbers) {
        const usageCountResponse = await axios.get(`/api/get-usage-count?phoneNumber=${key}`);
        const usageCount = usageCountResponse.data.usageCount;
        const bullets = 50 - usageCount;
        console.log(`Phone Number: ${key}, Usage Count: ${usageCount}, Bullets: ${bullets}`);
        bulletsInChamber[key] = bullets;
      }

      setBullets(bulletsInChamber);
      console.log('Bullets in chamber:', bulletsInChamber);
    } catch (error) {
      console.error('Error fetching phone numbers:', error);
    }
  };

  const handleFileLoad = (data) => {
    console.log('CSV Data Loaded:', data);
    if (data.length > 1) {
      const headers = data[0];
      const rows = data.slice(1);
      const formattedData = rows.map(row => {
        const rowData = {};
        row.forEach((value, index) => {
          rowData[headers[index]] = value;
        });
        return rowData;
      });
      setCsvData(formattedData);
      console.log('Formatted CSV Data:', formattedData);
    }
  };

  const composeMessage = (template, data) => {
    let message = template;
    for (const key in data) {
      const placeholder = `[${key}]`;
      message = message.split(placeholder).join(data[key]);
    }
    return message;
  };

  const handleQueue = async () => {
    const newQueue = [];
    const phoneNumbers = Object.keys(bullets);
    let phoneIndex = 0;

    for (const row of csvData) {
      if (row.phoneNumber) {
        const toPhoneNumber = row.phoneNumber.startsWith('+') ? row.phoneNumber : `+1${row.phoneNumber}`;
        const message = composeMessage(messageTemplate, row);
        const fromPhoneNumber = phoneNumbers[phoneIndex];
        console.log(`Queueing message to ${toPhoneNumber} from ${fromPhoneNumber}: ${message}`);
        newQueue.push({ toPhoneNumber, message, fromPhoneNumber });

        phoneIndex = (phoneIndex + 1) % phoneNumbers.length;
      } else {
        console.error('Missing phoneNumber in row:', row);
      }
    }
    setQueue(newQueue);
    if (!sending) {
      setSending(true);
      startSendingMessages(newQueue);
    }
  };

  const startSendingMessages = (queue) => {
    const sentNumbers = new Map();

    const sendNextMessage = async (index) => {
      if (index >= queue.length) {
        setSending(false);
        fetchBullets(); // Update bullets after sending messages
        return;
      }

      const { toPhoneNumber, message, fromPhoneNumber } = queue[index];
      console.log(`Sending message to ${toPhoneNumber} from ${fromPhoneNumber}: ${message}`);
      const now = Date.now();

      if (!sentNumbers.has(fromPhoneNumber) || (now - sentNumbers.get(fromPhoneNumber)) > 90000) { // 90 seconds safety
        try {
          await axios.post('/api/send-message', { phoneNumber: toPhoneNumber, message, fromNumber: fromPhoneNumber });
          await logFeedback(toPhoneNumber, message, 'Message sent successfully');
          setBullets((prev) => ({
            ...prev,
            [fromPhoneNumber]: prev[fromPhoneNumber] - 1
          }));
          sentNumbers.set(fromPhoneNumber, now);
        } catch (error) {
          console.error('Failed to send message:', error);
          await logFeedback(toPhoneNumber, message, `Failed to send message: ${error.message}`);
        }
        setTimeout(() => sendNextMessage(index + 1), 15000); // 15 seconds delay
      } else {
        setTimeout(() => sendNextMessage(index), 1000); // Retry after 1 second if the number was recently used
      }
    };

    sendNextMessage(0);
  };

  const logFeedback = async (phoneNumber, message, status) => {
    try {
      await axios.post('/api/log-feedback', {
        phoneNumber,
        message,
        status,
        timestamp: new Date().toISOString()
      });
    } catch (error) {
      console.error('Failed to log feedback:', error);
    }
  };

  return (
    <div>
      <h1>Empty The Clip</h1>
      <h2>Bullets in the Chamber</h2>
      <ul>
        {Object.keys(bullets).map((phoneNumber) => (
          <li key={phoneNumber}>{phoneNumber}: {bullets[phoneNumber]}</li>
        ))}
      </ul>
      <p>Total Bullets: {Object.values(bullets).reduce((acc, val) => acc + val, 0)}</p>
      <CSVReader onFileLoaded={handleFileLoad} />
      <textarea
        placeholder="Enter message template"
        value={messageTemplate}
        onChange={(e) => setMessageTemplate(e.target.value)}
      />
      <button onClick={handleQueue}>QUEUE</button>
    </div>
  );
};

export default EmptyTheClip;
