import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StarvingBoard = () => {
    const [data, setData] = useState([]);
    const [dateRange, setDateRange] = useState({ start: '', end: '' });

    // Replace this with a dynamic way to fetch all your users
    const allUsers = [
        'portorof@yoketeam.com',
        'bailey@bailey.com',
        'lottiechappell@yoketeam.com',
        'jamielawrence@yoketeam.com',
        'gracevonbiberstein@yoketeam.com',
        'lanecanova@yoketeam.com',
        'rossbernath@yoketeam.com',
        'hank@yoketeam.com'
    ];

    useEffect(() => {
        fetchData();
    }, [dateRange]);

    const fetchData = async () => {
        const startDate = dateRange.start ? new Date(dateRange.start) : new Date();
        startDate.setHours(0, 0, 0, 0); // Start of day
        const endDate = dateRange.end ? new Date(dateRange.end) : new Date();
        endDate.setHours(23, 59, 59, 999); // End of day

        try {
            // Fetch the call logs from Firebase
            const response = await axios.get('https://yoke-call-default-rtdb.firebaseio.com/callLogs.json');
            const filteredData = Object.values(response.data || {}).filter(call => {
                const callTime = new Date(call.callTime);
                return callTime >= startDate && callTime <= endDate;
            });

            // Initialize user stats map with all users having 0 calls and 0 answers
            const userStats = {};
            allUsers.forEach(user => {
                userStats[user] = { calls: 0, answered: 0 };
            });

            // Update stats based on the filtered call logs
            filteredData.forEach(call => {
                const userEmail = call.callUser;
                if (userStats[userEmail]) {
                    userStats[userEmail].calls++;
                    if (call.callResult === 'answered') {
                        userStats[userEmail].answered++;
                    }
                }
            });

            // Convert to array and sort by calls in descending order
            const sortedData = Object.entries(userStats)
                .map(([email, stats]) => ({ email, ...stats }))
                .sort((a, b) => b.calls - a.calls);

            setData(sortedData);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    return (
        <div>
            <h1>Starving Board</h1>
            <input type="date" value={dateRange.start} onChange={e => setDateRange(prev => ({ ...prev, start: e.target.value }))} />
            <input type="date" value={dateRange.end} onChange={e => setDateRange(prev => ({ ...prev, end: e.target.value }))} />
            <button onClick={fetchData}>Load Data</button>
            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Calls Made</th>
                        <th>Answers</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((user, index) => (
                        <tr key={index}>
                            <td>{user.email}</td>
                            <td>{user.calls}</td>
                            <td>{user.answered}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StarvingBoard;
