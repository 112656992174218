import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Header = () => {
  const { currentUser } = useAuth();

  const linkStyle = {
    marginRight: '15px',
    textDecoration: 'underline',
    color: 'blue'
  };

  return (
    <header>
      <h1>Welcome to Our App</h1>
      <nav>
        <Link to="/" style={linkStyle}>Home</Link>
        {currentUser ? (
          <>
            <Link to="/dashboard" style={linkStyle}>Dashboard</Link>
            <Link to="/starvingboard" style={linkStyle}>Starving Board</Link>
            <Link to="/emptytheclip" style={linkStyle}>Empty The Clip</Link>
            <Link to="/check-from-number" style={linkStyle}>Check From Number</Link> {/* New Tab */}
          </>
        ) : (
          <Link to="/login" style={linkStyle}>Login</Link>
        )}
      </nav>
    </header>
  );
};

export default Header;
