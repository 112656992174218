// src/utils/userDetails.js

const userNamesByEmail = {
    'bailey@bailey.com': 'Bailey',
    'bailey@yoketeam.com': 'Bailey',
    'hank@yoketeam.com': 'Hank',
    'autreybarnes@yoketeam.com': 'Autrey',
    'gracevonbiberstein@yoketeam.com': 'Grace',
    'lanecanova@yoketeam.com': 'Lane',
    'rossbernath@yoketeam.com': 'Ross',
    'jamielawrence@yoketeam.com': 'Jamie',
    'portorof@yoketeam.com': 'Portorof (He/Him)',
    'lottiechappell@yoketeam.com': 'Lottie'
};

export const getNameByEmail = (email) => {
    return userNamesByEmail[email] || 'Bailey'; // Default if email not found
};
