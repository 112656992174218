import React, { useState } from 'react';
import axios from 'axios';

const CheckFromNumber = () => {
  const [fromNumber, setFromNumber] = useState('');
  const [fetchedFromNumber, setFetchedFromNumber] = useState('');
  const [justCallEquivalent, setJustCallEquivalent] = useState('');

  const handleCheck = async () => {
    try {
      const response = await axios.get('/api/get-messages', {
        params: { number: fromNumber }
      });
      const { fromNumber: fetchedNumber, justCallEquivalent: equivalent } = response.data;
      setFetchedFromNumber(fetchedNumber);
      setJustCallEquivalent(equivalent);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setFetchedFromNumber('Error fetching equivalent');
      setJustCallEquivalent('');
    }
  };

  return (
    <div>
      <h2>Check From Number</h2>
      <input
        type="text"
        value={fromNumber}
        onChange={(e) => setFromNumber(e.target.value)}
        placeholder="Enter number in E.164 format"
      />
      <button onClick={handleCheck}>Fetch Messages</button>
      {fetchedFromNumber && (
        <div>
          <h3>From Number:</h3>
          <p>{fetchedFromNumber}</p>
          <h3>JustCall Equivalent:</h3>
          <p>{justCallEquivalent}</p>
        </div>
      )}
    </div>
  );
};

export default CheckFromNumber;
