import React from 'react';
import axios from 'axios';

function PostCallComponent({ setPhase, callDetails }) {
    // Function to log the call details to the database
    const logCallDetailsAndStartNewCall = async () => {
        try {
            // Send call details to the API
            const response = await axios.post('/api/log-call', callDetails);
            console.log('Call log saved:', response.data);
        } catch (error) {
            console.error('Failed to log call details:', error);
        } finally {
            // Move to the next phase regardless of success or failure
            setPhase('preCall');
        }
    };

    return (
        <div>
            <h2>Summary</h2>
            <p>Good job on completing the call!</p>
            <p>Call to Number: {callDetails.callToPhoneNumber}</p>
            <p>Call User: {callDetails.callUser}</p>
            <p>Call Time: {callDetails.callTime}</p>
            <p>Lead Type: {callDetails.callLeadType}</p>
            <p>SendBlue Phone Number: {callDetails.sendbluePhoneNumber}</p>
            <p>JustCall Phone Number: {callDetails.justCallPhoneNumber}</p>
            <p>Message Body: {callDetails.messageBody}</p>
            <button onClick={logCallDetailsAndStartNewCall}>Start Another Call</button>
        </div>
    );
}

export default PostCallComponent;
