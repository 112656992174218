import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import StarvingBoard from './pages/StarvingBoard';
import EmptyTheClip from './pages/EmptyTheClip'; 
import CheckFromNumber from './pages/CheckFromNumber'; // Import the new component

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/starvingboard" element={<StarvingBoard />} />
        <Route path="/emptytheclip" element={<EmptyTheClip />} /> 
        <Route path="/check-from-number" element={<CheckFromNumber />} /> {/* Add the new route */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
